import React from 'react';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Mixpanel } from '../../components/Mixpanel/Mixpanel.jsx';
import { database } from '../../firebaseConfig.js';
import { ref, get, goOffline } from 'firebase/database';
import { clearCustomizedMenu } from '../../redux/mealplan/mealplan.action.js';
import MessengerCustomerChat from 'react-messenger-customer-chat/lib/MessengerCustomerChat.js';
import ButtonCartCount from '../../components/common/ButtonCartCount';
import PostCodePopUp from '../../components/common/PostCodePopUp';
import MealPlans from '../../components/common/MealPlans';
import Footer from '../../components/common/Footer';
import Banner from '../../components/Home/Banner';
import AboutUs from '../AboutUs';
import './styles.css';

const Home = () => {
    const [postCodePopUp, setPostCodePopUp] = useState(false);
    const [mealPlans, setMealPlans] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();
    const mealPlansRef = useRef();
    const getStartedRef = useRef();
    const bannerRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            /* To be removed after June, 2024. */
            /* Temporary Code for removing old version of local storage */
            localStorage.removeItem('persist:quoality-guest');
            /* Temporary Code for removing old version of local storage */
            /* To be removed after June, 2024. */

            const mealplansDatabaseRef = ref(database, 'mealplans');

            // Set up a timeout promise in case Firebase reaches 100 simulataneous connections
            const timeout = new Promise(
                (_, reject) => setTimeout(() => reject(new Error('Request timed out')), 10000) // 10 seconds
            );

            try {
                // Race the timeout against the fetch request
                const snapshot = await Promise.race([get(mealplansDatabaseRef), timeout]);

                if (snapshot.exists()) {
                    const data = snapshot.val();

                    const fetchedVersion = data.version;
                    const storedVersion = parseInt(localStorage.getItem('mealPlansVersion'));
                    if (storedVersion !== fetchedVersion) {
                        dispatch(clearCustomizedMenu());
                        localStorage.setItem('mealPlansVersion', fetchedVersion.toString());
                    }

                    const fetchedMealplans = Object.keys(data)
                        .map((key) => ({
                            ...data[key],
                            id: key,
                        }))
                        .filter((item) => item.type === 'meal-plan')
                        .sort((a, b) => a.order - b.order);
                    setMealPlans(fetchedMealplans);
                } else {
                    console.log('No data available');
                    goOffline(database);
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
                goOffline(database);

                if (error.message === 'Request timed out') {
                    setErrorMessage('We are experiencing an overwhelming amount of orders at the moment!');
                } else {
                    setErrorMessage('An unexpected error has occurred and our developers have been immediately notified!');
                }
            }
        };
        fetchData();
    }, []);

    const scrollMealPlansIntoView = () => {
        Mixpanel.track('Button Tapped: View All Meal Plans', {
            page: 'HomePage',
        });
        getStartedRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollMealPlansIntoViewFromPostCodePopup = () => {
        Mixpanel.track('Button Tapped: View our meal plans', {
            page: 'PostCodePopUp',
        });
        setPostCodePopUp(false);
        getStartedRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleOpenCart = () => {
        Mixpanel.track('Button Tapped: Cart', {
            page: 'HomePage',
        });
        bannerRef.current.scrollIntoView();
        history.push('/cart');
    };

    function handleOpenMealPlan(mealPlanItem) {
        Mixpanel.track('Button Tapped: Add Me (on meal plan)', {
            name: mealPlanItem.title,
            page: 'HomePage',
        });
        window.scrollTo(0, 0);
        history.push('/meal-plan', [mealPlanItem]);
    }

    function openPostCodePopUp() {
        Mixpanel.track('Button Tapped: Check if we deliver to you', {
            page: 'HomePage',
        });
        setPostCodePopUp(true);
    }

    function closePostCodePopUp() {
        Mixpanel.track('Button Tapped: Close', {
            page: 'PostCodePopUp',
        });
        setPostCodePopUp(false);
    }

    return (
        <div>
            <MessengerCustomerChat
                pageId='106384542128247'
                appId='557417392694422'
                version='14.0'
                debug={location.hostname === 'localhost'}
                language='en_GB'
            />
            <div className='header-background'></div>
            <Banner handleScrollMealPlans={scrollMealPlansIntoView} ref={bannerRef} />
            <AboutUs />
            <div className='lets-get-started' ref={getStartedRef}>
                {errorMessage == null ? <h1>Let&apos;s get started!</h1> : <h1>Oops!</h1>}
            </div>
            {errorMessage == null ? (
                <MealPlans list={mealPlans} handleOpenMealPlan={handleOpenMealPlan} ref={mealPlansRef} />
            ) : (
                <div className='firebase-error-div'>
                    <p>Sincerest apologies!</p>
                    <p>
                        <i className='fa-solid fa-triangle-exclamation'></i> {errorMessage}
                    </p>
                    <h2>Please try again later.</h2>
                    <h2>Thank you for your patience!</h2>
                </div>
            )}
            <Footer />
            <button className='check-delivery-button' onClick={openPostCodePopUp}>
                <i className='fa-solid fa-box-open'></i> Check if we deliver to you
            </button>
            <ButtonCartCount handleOpenCart={handleOpenCart} />
            <PostCodePopUp
                closePostCodePopUp={closePostCodePopUp}
                viewMealPlans={scrollMealPlansIntoViewFromPostCodePopup}
                postCodePopUp={postCodePopUp}
            />
        </div>
    );
};

export default Home;
