import { selectCartItemsCount } from '../../../../redux/cart/cart.selector';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { forwardRef } from 'react';
import React from 'react';
import './styles.css';

const CartMenu = forwardRef(({ cartCount, list }, ref) => {
    function areThereItemsInCart() {
        if (cartCount != null) {
            return cartCount !== 0;
        } else {
            return false;
        }
    }

    const Info = (item) => {
        const minCalories = item.item.minCalories;
        const maxCalories = item.item.maxCalories;
        
        if (maxCalories === minCalories) {
            return (
                <p className='meal-plan-info'>
                    <span>{minCalories} Calories</span>
                </p>
            );
        } else {
            return (
                <p className='meal-plan-info'>
                    <span>{minCalories} - {maxCalories} Calories</span>
                </p>
            );
        }
    };

    return (
        areThereItemsInCart() ?
            <main className='menu-cart-main' ref={ref}>
                {
                    list.filter((item) => (
                        item.type === 'meal-plan'
                    )).map((item, index) => (
                        <div key={item.id || index} className='meal-plan-cart-item-div'>
                            <img src={item.imageUrl} alt='meal-plan-image'></img>
                            <div className='meal-plan-cart-item-info-div'>
                                <h1>{item.title}</h1>
                                <Info item={item} />
                            </div>
                        </div>
                    ))
                }
            </main> :
            null
    );
});

const mapStateToProps = createStructuredSelector({
    cartCount: selectCartItemsCount,
});

export default connect(mapStateToProps)(CartMenu);
