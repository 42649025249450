import React from 'react';
import PaymentForm from '../../components/PaymentForm';
import { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { addUser } from '../../redux/user/user.action';
import { selectUserItem } from '../../redux/user/user.selector';
import { Mixpanel } from '../../components/Mixpanel/Mixpanel';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './styles.css';

// eslint-disable-next-line
const STRIPE_PUBLIC_KEY_TEST = "pk_test_51KiNNxIJsRU3NrL6hjk2WsS42Ww3vbUepszzMxRtkJ8eEGMOd35bIosepRO0RMdYo0iVDq6wDfqNE24lvJQZeyJ500WqdFX1Pu"
// eslint-disable-next-line
const STRIPE_PUBLIC_KEY_PROD = "pk_live_51KiNNxIJsRU3NrL6Gh2yTxyQYnz8xXtY15Hv15d49X0tqNHtKQcjIbo0aJJsCokJ3IshLPXgcT9n5D3v5AJ7RBvM00nrYtJ7MZ"
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY_PROD);

const Payment = ({ cartList, cartTotal, deliveryDate, secondDeliveryDate, userItem, addUser }) => {
    const [customer, setCustomer] = useState(userItem.user);
    const [customerDetailsAreAdded, setCustomerDetailsAreAdded] = useState(areCustomerDetailsAdded());

    function handleInputChange(e) {
        const newCustomer = customer;

        switch (e.target.id) {
        case 'first-name':
            newCustomer.firstName = e.target.value;
            break;
        case 'last-name':
            newCustomer.lastName = e.target.value;
            break;
        case 'email':
            newCustomer.email = e.target.value;
            break;
        case 'confirm-email':
            newCustomer.confirmedEmail = e.target.value;
            break;
        case 'phone-number':
            newCustomer.phoneNumber = e.target.value;
            break;
        case 'street-name':
            newCustomer.streetName = e.target.value;
            break;
        case 'street-number':
            newCustomer.streetNumber = e.target.value;
            break;
        case 'post-code':
            newCustomer.postCode = e.target.value.toUpperCase();
            break;
        case 'allergens':
            newCustomer.allergens = e.target.value;
            break;
        case 'delivery-note':
            newCustomer.deliveryNote = e.target.value;
            break;
        default:
            break;
        }

        addUser({ user: newCustomer });

        if (areCustomerDetailsAdded()) {
            setCustomerDetailsAreAdded(true);

            Mixpanel.track('Operation: Customer successfully added all their details', {
                'firstName': customer.firstName,
                'lastName': customer.lastName,
                'email': customer.email,
                'confirmedEmail': customer.confirmedEmail,
                'phoneNumber': customer.phoneNumber,
                'streetName': customer.streetName,
                'streetNumber': customer.streetNumber,
                'postCode': customer.postCode,
                'page': 'Cart',
            });
        } else {
            setCustomerDetailsAreAdded(false);
        }

        setCustomer(newCustomer);
    }

    function areCustomerDetailsAdded() {
        return (customer.firstName != null &&
      customer.firstName.length > 0 &&
      customer.lastName != null &&
      customer.lastName.length > 0 &&
      customer.email != null &&
      customer.email.length > 0 &&
      customer.confirmedEmail != null &&
      customer.confirmedEmail.length > 0 &&
      customer.phoneNumber != null &&
      customer.phoneNumber.length > 0 &&
      customer.email === customer.confirmedEmail &&
      customer.streetName != null &&
      customer.streetName.length > 0 &&
      customer.streetNumber != null &&
      customer.streetNumber.length > 0 &&
      customer.postCode != null);
    }

    return (
        <div>
            <div>
                <form className='user-payment-form'>
                    <p className='user-payment-form-description'><i className="fa-solid fa-user"></i> To whom should we deliver this order?</p>
                    <div className='user-payment-form-customer-details'>
                        <input type='text' id='first-name' name='first-name' placeholder='First Name' value={customer.firstName} onChange={handleInputChange}></input>
                        <input type='text' id='last-name' name='last-name' placeholder='Last Name' value={customer.lastName} onChange={handleInputChange}></input>
                        <input type='text' id='phone-number' name='phone-number' placeholder='Phone Number' value={customer.phoneNumber} onChange={handleInputChange}></input>
                    </div>
                    <div className='user-payment-form-email'>
                        <input type='text' id='email' name='email' placeholder='E-mail' value={customer.email} onChange={handleInputChange}></input>
                        <input type='text' id='confirm-email' name='confirm-email' placeholder='Confirm E-mail' value={customer.confirmedEmail} onChange={handleInputChange}></input>
                    </div>
                    <div className='user-payment-form-address'>
                        <input className='user-payment-form-address-street-name' type='text' id='street-name' name='street-name' placeholder='Street Name' value={customer.streetName} onChange={handleInputChange}></input>
                        <input className='user-payment-form-address-street-number' type='text' id='street-number' name='street-number' placeholder='Street Number' value={customer.streetNumber} onChange={handleInputChange}></input>
                        <input className='user-payment-form-address-post-code' type='text' id='post-code' name='post-code' placeholder='Post Code' value={customer.postCode} onChange={handleInputChange}></input>
                        <input className='user-payment-form-address-city' type='text' id='city' name='city' value='London, UK' disabled></input>
                    </div>
                    <p className='user-payment-form-description'><i className="fa-solid fa-triangle-exclamation"></i> Do you have any allergies or dietary restrictions?</p>
                    <textarea className='user-payment-form-delivery-note' id='allergens' onChange={handleInputChange} placeholder='Allergens (optional field)' value={customer.allergens}></textarea>
                    <p className='user-payment-form-description'><i className="fa-solid fa-circle-info"></i> Is there any additional info you&apos;d like to give about your order or delivery?</p>
                    <textarea className='user-payment-form-delivery-note' id='delivery-note' onChange={handleInputChange} placeholder='Delivery Note (optional field)' value={customer.deliveryNote}></textarea>
                </form>
            </div>

            <p className='user-payment-form-card-description'><i className="fa-solid fa-lock"></i> We securely process your card details for a one time payment only, cards are not saved.</p>
            <Elements stripe={stripePromise}>
                <PaymentForm
                    customer={customer}
                    customerDetailsAreAdded={customerDetailsAreAdded}
                    cartList={cartList}
                    cartTotal={cartTotal}
                    deliveryDate={deliveryDate}
                    secondDeliveryDate={secondDeliveryDate} />
            </Elements>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    userItem: selectUserItem,
});

const mapDispatchToProps = (dispatch) => ({
    addUser: (item) => dispatch(addUser(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
