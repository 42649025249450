import React from 'react';
import axios from 'axios';
import LoadingPopUp from '../common/LoadingPopUp';
import PurchaseInfoPopUp from '../common/PurchaseInfoPopUp';
import { useStateIfMounted } from 'use-state-if-mounted';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { Mixpanel } from '../../components/Mixpanel/Mixpanel';
import { cartRemoveAllItems } from '../../redux/cart/cart.action';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import './styles.css';

<script src='https://js.stripe.com/v3/'></script>;

// eslint-disable-next-line
const prodApi = 'https://mokkafoodapi.co.uk:8443';
// eslint-disable-next-line
const devApi = 'https://localhost:8443';

const CARD_OPTIONS = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#555',
            color: '#111',
            fontWeight: 900,
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': { color: '#555' },
            '::placeholder': { color: '#555' },
        },
        invalid: {
            iconColor: '#f30',
            color: '#f30',
        },
    },
};

const PaymentForm = ({ customer, customerDetailsAreAdded, cartList, cartTotal, deliveryDate, secondDeliveryDate, cartRemoveAllItems }) => {
    const [success, setSuccess] = useStateIfMounted(false);
    const [isLoadingPopUpShown, setIsLoadingPopUpShown] = useStateIfMounted(false);
    const [isPurchaseInfoPopUpShown, setIsPurchaseInfoPopUpShown] = useStateIfMounted(false);
    const [serverMessage, setServerMessage] = useStateIfMounted('');

    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();

    function calculateDistance(lat1, lon1, lat2, lon2) {
        // const R = 3958.8; // Radius of the Earth in miles
        const radLat1 = Math.PI * lat1 / 180;
        const radLat2 = Math.PI * lat2 / 180;
        const theta = lon1 - lon2;
        const radTheta = Math.PI * theta / 180;
        let dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
    
        return dist;
    }

    async function checkPostCode() {
        if (!customer.postCode) {
            return {
                postCodeError: 'The post code field is empty. Please enter a post code.',
                isPostCodeSupported: false,
            };
        }

        const cleanPostCode = customer.postCode.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');

        try {
            const response = await fetch(`https://api.postcodes.io/postcodes/${cleanPostCode}`);

            if (!response.ok) {
                throw new Error('Postcode lookup failed');
            }

            const data = await response.json();

            if (data.status === 200 && data.result) {
                // Coordinates of E4 9EP
                const baseLatitude = 51.617997;
                const baseLongitude = -0.013842;

                const distanceInMiles = calculateDistance(baseLatitude, baseLongitude, data.result.latitude, data.result.longitude);

                if (distanceInMiles <= 5) {
                    Mixpanel.track('Operation: Post code is supported! [successful operation]', {
                        userInput: customer.postCode,
                        page: 'Cart',
                    });
                    return {
                        postCodeError: null,
                        isPostCodeSupported: true,
                    };
                } else {
                    Mixpanel.track('Operation: Post code is in unsupported delivery region', {
                        userInput: customer.postCode,
                        page: 'Cart',
                    });
                    return {
                        postCodeError: `Unfortunately, we don't deliver to post code ${customer.postCode} yet. You could send us an e-mail to request delivery for your location at orders@mokkafood.co.uk!`,
                        isPostCodeSupported: false,
                    };
                }
            } else {
                return {
                    postCodeError: `Invalid post code ${customer.postCode}. Please enter a valid post code.`,
                    isPostCodeSupported: false,
                };
            }
        } catch (error) {
            console.error(error);
            Mixpanel.track('Operation: api.postcodes.io failed to find post code', {
                userInput: customer.postCode,
                postcodesioError: error,
                page: 'Cart',
            });
            return {
                postCodeError: `Invalid post code ${customer.postCode}. Please enter a valid post code.`,
                isPostCodeSupported: false,
            };
        }
    }

    const handleSubmit = async (e) => {
        setIsLoadingPopUpShown(true);
        e.preventDefault();

        Mixpanel.track('Button Tapped: Purchase meal plan(s)', {
            totalPrice: (cartTotal / 100).toString(),
            page: 'Cart',
        });

        // CHECK IF POST CODE IS IN SUPPORTED DELIVERY REGION
        const { postCodeError, isPostCodeSupported } = await checkPostCode();        

        // CHECK IF CUSTOMER DETAILS ARE MISSING
        if (customerDetailsAreAdded && isPostCodeSupported) {
            setServerMessage('Processing payment...');

            // CREATE PAYMENT (CAN FAIL IF CARD IS INCORRECT)
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });

            // CHECK IF PAYMENT WAS CREATED SUCCESSFULLY
            if (!error) {
                try {
                    const { id } = paymentMethod;
                    const totalPriceInCents = parseFloat(cartTotal);
                    
                    // ATTEMPT PAYMENT
                    const response = await axios.post(devApi + '/payment', {
                        customerFirstName: customer.firstName,
                        customerLastName: customer.lastName,
                        customerEmail: customer.email,
                        customerPhoneNumber: customer.phoneNumber,
                        customerStreetName: customer.streetName,
                        customerStreetNumber: customer.streetNumber,
                        customerPostCode: customer.postCode,
                        customerCity: customer.city,
                        customerCountry: customer.country,
                        customerAllergens: customer.allergens == null ? '' : customer.allergens,
                        customerDeliveryNote: customer.deliveryNote == null ? '' : customer.deliveryNote,
                        cartItems: cartList,
                        deliveryDate: deliveryDate,
                        secondDeliveryDate: secondDeliveryDate,
                        amount: totalPriceInCents,
                        id: id,
                    });

                    // PAYMENT SUCCESSFUL
                    if (response.data.success) {
                        Mixpanel.track('Operation: PAYMENT SUCCESSFUL', {
                            serverMessage: response.data.message,
                            orderNumber: response.data.orderNumber.toString(),
                            page: 'Cart',
                        });

                        setServerMessage(response.data.orderNumber.toString());

                        setSuccess(true);
                        setIsLoadingPopUpShown(false);
                        setIsPurchaseInfoPopUpShown(true);

                        // PAYMENT PROCESSING ERROR
                    } else {
                        const errorMessage = response.data.message.raw.message;

                        if (errorMessage != null && typeof errorMessage == 'string') {
                            Mixpanel.track('Operation: Failed to make payment', {
                                error: errorMessage,
                                page: 'Cart',
                            });
                            setServerMessage(errorMessage);
                        } else {
                            Mixpanel.track('Operation: Failed to make payment', {
                                error: 'Unknown Error Occurred',
                                page: 'Cart',
                            });
                            setServerMessage('Unknown Error Occurred');
                        }

                        setSuccess(false);
                        setIsLoadingPopUpShown(false);
                        setIsPurchaseInfoPopUpShown(true);
                    }
                    // PAYMENT PROCESSING ERROR
                } catch (error) {
                    Mixpanel.track('Operation: Failed to make payment', {
                        error: 'Unknown Error Occurred',
                        page: 'Cart',
                    });

                    setServerMessage('Unknown Error Occurred');
                    setSuccess(false);
                    setIsLoadingPopUpShown(false);
                    setIsPurchaseInfoPopUpShown(true);
                }
                // CARD DETAILS ERROR
            } else {
                let errorMessage = 'Unknown Error Occurred';

                if (error != null) {
                    if (error.message != null) {
                        errorMessage = error.message;
                    }
                }

                Mixpanel.track('Operation: Failed to make payment', {
                    error: errorMessage,
                    page: 'Cart',
                });

                setServerMessage(errorMessage);
                setSuccess(false);
                setIsLoadingPopUpShown(false);
                setIsPurchaseInfoPopUpShown(true);
            }
            // CUSTOMER DETAILS OR POST CODE ERROR
        } else {
            const validationErrorMessage =
                postCodeError ??
                'One or more mandatory fields is empty or the emails do not match. Please double check the details you have entered.';

            Mixpanel.track('Operation: Failed to make payment', {
                error: validationErrorMessage,
                page: 'Cart',
            });

            setTimeout(function () {
                setServerMessage(validationErrorMessage);
                setSuccess(false);
                setIsLoadingPopUpShown(false);
                setIsPurchaseInfoPopUpShown(true);
            }, 500);
            e.preventDefault();
        }
    };

    function hidePopUp() {
        setIsPurchaseInfoPopUpShown(false);
    }

    function clearCartAndLeavePage() {
        Mixpanel.track('Button Tapped: Clear All Items in Cart', {
            page: 'Cart',
        });
        cartRemoveAllItems();
        setIsPurchaseInfoPopUpShown(false);
        window.scrollTo(0, 0);
        history.push('/');
    }

    return (
        <>
            {
                <form onSubmit={handleSubmit}>
                    <fieldset className='FormGroup'>
                        <div className='FormRow'>
                            <CardElement options={CARD_OPTIONS} />
                        </div>
                    </fieldset>
                    <div className='stripe-div'>
                        <p>
                            Payments are securely processed by <i className='fa-brands fa-stripe stripe-logo'></i> Stripe, Inc.
                        </p>
                    </div>
                    <button className='payment-button'>
                        <i className='fa-regular fa-credit-card'></i>Purchase meal plan(s) for £{cartTotal / 100}
                    </button>
                    <br />
                    <br />
                    <br />
                    <LoadingPopUp isLoadingPopUpShown={isLoadingPopUpShown} />
                    <PurchaseInfoPopUp
                        isPurchaseInfoPopUpShown={isPurchaseInfoPopUpShown}
                        serverMessage={serverMessage}
                        success={success}
                        handleOkayButton={hidePopUp}
                        handleSuccessButton={clearCartAndLeavePage}
                    />
                </form>
            }
        </>
    );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
    cartRemoveAllItems: () => dispatch(cartRemoveAllItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
