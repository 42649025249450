import { createSelector } from 'reselect';

const selectMealPlan = (state) => state.mealplan;

export const selectMealPlans = createSelector(
    [selectMealPlan],
    (mealPlan) => {
        console.log('Current state in selector:', mealPlan);
        return mealPlan.customizedMenu;
    }
);

export const selectMealPlanById = (mealPlanId) => createSelector(
    [selectMealPlans],
    (customizedPlans) => customizedPlans[mealPlanId] || {}
);

export const selectMealPlanTypeById = (mealPlanId, mealType) => createSelector(
    [selectMealPlanById(mealPlanId)],
    (customizedPlan) => customizedPlan[mealType] || []
);