import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from "firebase/functions";
import { getDatabase } from 'firebase/database';
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const firebaseConfig = {
  apiKey: "AIzaSyDACEeGjdNVRzK41BsQ7EdGy2SBJRGBw48",
  authDomain: "mokkafood-neli.firebaseapp.com",
  databaseURL: "https://mokkafood-neli-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mokkafood-neli",
  storageBucket: "mokkafood-neli.appspot.com",
  messagingSenderId: "207179938669",
  appId: "1:207179938669:web:14cc5e9eddf32a0043f864",
  measurementId: "G-TQ03W7EVNB"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
export const functions = getFunctions(firebaseApp);
export const database = getDatabase(firebaseApp);
export const storage = getStorage(firebaseApp, "gs://mokkafood-neli.appspot.com");

if (location.hostname === "localhost") {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
} else {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider('6LceHVopAAAAAKOjxPJyp4ru-2TWLkAMbYOYf8oZ'),
    isTokenAutoRefreshEnabled: true
  });
}