import React from 'react';
import './styles.css';

const PurchaseInfoPopUp = ({ isPurchaseInfoPopUpShown, serverMessage, success, handleOkayButton, handleSuccessButton }) => {
    return (
        isPurchaseInfoPopUpShown === true ?
            <div className='modal-are-you-sure'>
                <div className='modal-content-payment-info modal-content-are-you-sure'>
                    <h1 className='payment-info-h1'>{success ? 'Payment successful!' : 'Oops!'}</h1>
                    {
                        success ?
                            <div>
                                <p className='payment-info-p do-not-close'><i className="fa-solid fa-circle-check"></i> Your order number is: {serverMessage}</p>
                                <p className='payment-info-p do-not-close'><i className="fa-solid fa-circle-check"></i> We&apos;ve sent you an email with your order details.</p>
                                <p>(Make sure to check the spam folder also!)</p>
                                <br />
                                <p className='payment-info-p do-not-close'><i className="fa-regular fa-face-laugh-beam"></i> Thank you for ordering from MokkaFood and supporting our new business!</p>
                                <br />
                                <p className='payment-info-p do-not-close'>We wish you a fantastic day!</p>
                                <button className='payment-info-button are-you-sure-yes-button' onClick={handleSuccessButton}>
                        Thank you!
                                </button>
                            </div> :
                            serverMessage === 'Customer details are missing.' ?
                                <div>
                                    <p className='payment-info-p do-not-close'><i className="fa-solid fa-user-pen"></i> {serverMessage}</p>
                                    <button className='payment-info-button are-you-sure-yes-button' onClick={handleOkayButton}>
                        Okay, I&apos;ll check
                                    </button>
                                </div> :
                                <div>
                                    <p className='payment-info-p do-not-close'><i className="fa-solid fa-circle-exclamation"></i> {serverMessage}</p>
                                    <button className='payment-info-button are-you-sure-yes-button' onClick={handleOkayButton}>
                        Okay
                                    </button>
                                </div>
                    }
                </div>
            </div> :
            null
    );
};

export default PurchaseInfoPopUp;
